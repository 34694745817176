<template>
  <div class="panel-progress-linear">
    <v-progress-linear indeterminate class="panel-progress-linear__loader" />
  </div>
</template>
<script>
export default {
  name: "PanelProgressLinearIndicator"
}
</script>
<style lang="scss">
.panel-progress-linear {
  background: #0b0b0b91;
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  &__loader {
    margin-top: 0;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-radius: 4px;
    z-index: 5;
  }
}
</style>

<template>
  <div class="custom-drawer">
    <div>
      <slot />
    </div>

    <v-navigation-drawer
      :width="width"
      :height="height"
      app
      :temporary="isTemporary"
      dark
      hide-overlay
      :left="left"
      :right="right"
      class="custom-drawer__main-menu"
      v-model="localShowMainMenu"
      style="background: #1e2025"
      :style="menuStyles"
      @transitionend="$emit('refresh')"
    >
      <PanelProgressLinear v-if="isLoading && showMainMenu" />
      <div class="relative flex">
        <slot name="menu-content" />
      </div>
    </v-navigation-drawer>
    <v-navigation-drawer
      app
      :temporary="isTemporary"
      dark
      hide-overlay
      :left="left"
      :right="right"
      :width="submenuWidth"
      :height="height"
      v-model="localShowSubMenu"
      class="custom-drawer__main-submenu"
      :style="submenuStyles"
    >
      <PanelProgressLinear v-if="isLoading && showSubMenu" />
      <div class="relative flex">
        <slot name="back-button" :back="back">
          <v-btn
            v-if="subMenuTitle"
            class="px-1 ma-1"
            style="text-transform: none"
            flat
            @click.stop="back"
          >
            <v-icon> keyboard_arrow_left </v-icon>
            <span style="font-size: 22px; font-weight: bold">
              {{ subMenuTitle }}
            </span>
          </v-btn>
        </slot>
        <slot name="submenu-content" :back="back" />
      </div>
    </v-navigation-drawer>
  </div>
</template>
<script>
import { delay } from "lodash"
import { RtbControlButton } from "@/components/ui"
import PanelProgressLinear from "@/components/ActivityMenuPanels/PanelProgressLinear"

export default {
  name: "CustomDrawer",
  components: { RtbControlButton, PanelProgressLinear },
  model: {
    prop: "showMainMenu",
    event: "input"
  },
  props: {
    fullHeight: {
      type: Boolean,
      default: false
    },
    width: {
      type: Number,
      default: 380
    },
    height: {
      type: Number
    },
    submenuWidth: {
      type: Number,
      default: 380
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    },
    showMainMenu: {
      type: [Boolean, String]
    },
    showSubMenu: {
      type: Boolean
    },
    subMenuTitle: {
      type: [String, Boolean]
    },
    offsetY: {
      type: Number,
      default: 40
    },
    menuBackground: {
      type: String,
      default: "#1E2025"
    },
    subMenuBackground: {
      type: String
    },
    showToggleBtn: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean
    },
    isTemporary: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    localShowMainMenu: {
      get() {
        return this.showMainMenu
      },
      set(val) {
        this.$emit("input", val)
      }
    },
    localShowSubMenu: {
      get() {
        return this.showSubMenu
      },
      set(val) {
        this.$emit("updateShowSubMenu", val)
      }
    },
    isDrawerVisible() {
      return this.localShowMainMenu || this.localShowSubMenu
    },
    menuStyles() {
      return this.makeCustomStyles(this.menuBackground)
    },
    submenuStyles() {
      return this.makeCustomStyles(this.subMenuBackground)
    }
  },
  methods: {
    toggleDrawer() {
      this.localShowMainMenu = !this.localShowMainMenu
    },
    closeDrawer() {
      this.localShowMainMenu = false
      this.localShowSubMenu = false
    },
    back() {
      this.localShowSubMenu = false
      delay(() => (this.localShowMainMenu = true), 100)
    },

    makeCustomStyles(menuBackground) {
      const customStyles = {}
      const background = menuBackground && `${menuBackground} !important`

      let marginTop = "0px"
      let height

      // TODO:
      // remove all this js computed style business
      if (this.fullHeight) {
        height = !this.height ? `calc(100vh - 0px) !important` : this.height
        if (this.offsetY >= 0) {
          marginTop = `${this.offsetY}px`
        }
      } else {
        height = !this.height ? `calc(100vh - 60px) !important` : this.height
        if (this.offsetY >= 0 && !this.height) {
          marginTop = `${this.offsetY}px`
          height = `calc(100vh - ${60 + this.offsetY}px) !important`
        }
      }

      customStyles.marginTop = marginTop
      customStyles.height = height
      customStyles.background = background

      if (this.isLoading) {
        customStyles["overflow-y"] = "hidden"
      }

      return customStyles
    }
  },
  watch: {
    localShowSubMenu(val) {
      if (val) {
        this.localShowMainMenu = false
      }
    }
  }
}
</script>
<style lang="scss">
.custom-drawer {
  z-index: 1110;
  &__main-menu,
  &__main-submenu {
    display: flex;
    height: calc(100vh - 100px) !important;
    z-index: 101;
    box-shadow: none !important;
    background-color: #1e2025;
  }

  &__btn {
    min-width: 60px;
    height: 32px;
    padding-left: 8px;
    padding-right: 8px;
    box-shadow: none !important;
    border-radius: 3px;
    margin: 0 8px;

    &--close {
      background: #1e2025 !important;
    }
  }
}
</style>
